<template>
<div class="scope">
    <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true" :no-close-on-backdrop="true">
        <Login />
    </b-modal>
    <p class="pageTopic">ข้อมูลส่วนตัว</p>
    <b-row cols="3" cols-sm="2">
        <b-col class="pl-0 marginTop" style="paddingRight:3px" v-for="(menuList,index) in menuLists" :key="index">
            <div v-if="$vssWidth<1240" :id="'menuListBox'+index" @click="menuListBoxActive(index, menuList.href)" class="menuListBox text-left mb-3 pl-3">
                <img class="mr-2" :src="require(`@/assets/${menuList.src}`)" width="18px" />
                <span>{{menuList.menuName}}</span>
            </div>
        </b-col>
    </b-row>
    <b-row>
        <b-col sm="2" class="pl-0 pr-4 marginTop" v-if="$vssWidth>=1240">
            <div :id="'menuListBox'+index" @click="menuListBoxActive(index, menuList.href)" v-for="(menuList,index) in menuLists" :key="index" class="menuListBox text-left mb-3 pl-3">
                <img class="mr-2" :src="require(`@/assets/${menuList.src}`)" width="22px" />
                <span>{{menuList.menuName}}</span>
            </div>
        </b-col>
        <b-col id="showDetail" class="showDetail" v-if="page == 'edit' || page == 'history'">
            <EditProfile v-if="page == 'edit'" @emitModeToParent="checkMode" />
            <CoinHistory v-if="page == 'history'" @emitModeToParent="checkMode" />
        </b-col>

        <b-col xl="10" v-else>
            <MyBookFav v-if="page == 'myBookFav'" />
            <MyBookBuy v-if="page == 'myBookBuy'" />
        </b-col>
    </b-row>
    <!-- table chapter -->
    <b-row>
        <b-col sm="2" v-if="$vssWidth>=1240"></b-col>
        <b-col class="chaptertable">
            <div id="tableScope" class="tableScope mt-3 mb-5" v-if="page == 'myBookBuy' && isShowChapter">
                <div class="text-center" v-if="isBusy">
                    <b-row>
                        <b-col>
                            <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <div class="headTable text-center pb-3">
                        <span class="fontsize mr-3">ตอนที่ซื้อทั้งหมด {{items.length}} ตอน</span>
                    </div>
                    <b-row v-for="(buychapter,index) in items" :key="index" @click="goToChapter(buychapter.chapter_id)" class="pt-2 pb-2 mt-3 mb-2 chapter">
                        <b-col>ตอนที่ {{buychapter.chapter_ep}}: {{buychapter.chapter_name}}</b-col>
                        <b-col cols="4" sm="2">{{buychapter.chapter_length}} อักษร</b-col>
                    </b-row>
                </div>
            </div>
        </b-col>
    </b-row>
</div>
</template>

<script>
import EditProfile from "./../elements/profile/EditProfile"
import MyBookFav from "./../elements/profile/MyBookFav"
import MyBookBuy from "./../elements/profile/MyBookBuy"
import CoinHistory from "./../elements/profile/CoinHistory"
import axios from 'axios'
import router from '../../router'
import Login from "./../pages/Login"
import {
    mapActions,
    mapGetters
} from 'vuex'
var queryParams = new URLSearchParams(window.location.search);
export default {
    data() {
        return {
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            isBusy: true,
            isShowChapter: false,
            islogin: localStorage.getItem('UserInfo') != null,
            items: [{
                chapter_name: "",
                chapter_ep: 0,
                chapter_length: 0
            }],
            page: queryParams.get("menu"),
            menuLists: [{
                    href: 'edit',
                    src: 'paper.png',
                    menuName: 'แก้ไขข้อมูล'
                },
                {
                    href: 'myBookFav',
                    src: 'books.png',
                    menuName: 'ชั้นหนังสือ'
                },
                {
                    href: 'myBookBuy',
                    src: 'open-book.png',
                    menuName: 'รวมหนังสือที่เคยซื้อ'
                },
                {
                    href: 'history',
                    src: 'money.png',
                    menuName: 'ประวัติการเติมเงิน'
                },
            ],
            novel_id: 0,
            showLoginModal: false
        }
    },
    metaInfo() {
        return { 
            title: "ข้อมูลส่วนตัว | NovelRealm ศูนย์รวมนิยายแปล นิยายแต่ง ขึ้นหิ้งมากมาย",
            meta: [
                { name: 'description', content:  'NovelRealm นิยายแปลที่ถูกคัดสรรมาอย่างดี การันตีด้วยยอดผู้อ่านกว่าล้านครั้ง ทั้งนิยายแฟนตาซี นิยายกำลังภายใน นิยายรักโรแมนติก'},
                { property: 'og:title', content: "ข้อมูลส่วนตัว | NovelRealm ศูนย์รวมนิยายแปล นิยายแต่ง ขึ้นหิ้งมากมาย"}
            ]
        }
    },
    components: {
        EditProfile,
        MyBookFav,
        MyBookBuy,
        CoinHistory,
        Login
    },
    mounted() {
        //document.title = 'ข้อมูลส่วนตัว';
        this.firstAction()
        this.checkLogin()
        this.$root.$on('getChapter', (isShowChapter, novel_id) => {
            this.isShowChapter = isShowChapter
            this.getChapter(novel_id)
            this.novel_id = novel_id
        })
        this.getProfile()
        this.checkMode()
    },
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        },
        // isShowChapter() {
        //     this.checkMode()
        // }
    },
    methods: {
        ...mapActions(['SET_MEMBER_GOLD']),
        checkLogin(){
            if(this.islogin == false){
                this.showLoginModal = true
            }
        },
        async getProfile() {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios.post('https://api2.novelrealm.com/module/profile&submodule=info', formData)
                    .then((res) => {
                        if (res.status == 200) {
                            this.SET_MEMBER_GOLD(res.data.member_gold)
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        firstAction() {
            //console.log('queryParams.get("menu") : ', queryParams.get("menu"))
            if (queryParams.get("menu") == null) {
                this.setParamMenu('edit')
                this.$nextTick(() => {
                    this.menuListBoxActive(0, "edit")
                })
            } else if (queryParams.get("menu") != null) {
                if (queryParams.get("menu") == "edit" || queryParams.get("menu") == "myBookFav" || queryParams.get("menu") == "myBookBuy" || queryParams.get("menu") == "history") {
                    var index = this.menuLists.findIndex(x => x.href === queryParams.get("menu"))
                    this.$nextTick(() => {
                        this.menuListBoxActive(index, queryParams.get("menu"))
                    })
                } else {
                    this.setParamMenu('edit')
                    this.$nextTick(() => {
                        this.menuListBoxActive(0, "edit")
                    })
                }
            }
        },
        setParamMenu(menu) {
            queryParams.set("menu", menu);
            history.replaceState(null, null, "?" + queryParams.toString());
        },
        menuListBoxActive(index, href) {
            this.setParamMenu(href)
            this.page = queryParams.get("menu")
            for (var n = 0; n < 4; n++) {
                document.getElementById('menuListBox' + n).classList.remove("menuListBoxActive")
            }
            document.getElementById('menuListBox' + index).classList.add("menuListBoxActive")
        },
        async getChapter(novel_id) {
            this.isBusy = true
            const formData = new FormData();
            formData.append('novel_id', novel_id);
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios
                    .post("https://api2.novelrealm.com/module/profile&submodule=buychapterlist", formData)
                    .then(res => {
                        this.items = (res.data).sort((a, b) => a.chapter_ep - b.chapter_ep)
                        //console.log("chapter : ", this.items)
                        this.checkMode()
                        this.isBusy = false
                    })
                    .catch(err => {
                        console.error("error : " + err);
                    });
            } catch (err) {
                console.error("error : " + err);
            }
        },
        goToChapter(chapter_id) {
            router.push('/novel/' + this.novel_id + '/chapter/' + chapter_id)
            //console.log('goToChapter : ', chapter_id)
        },
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            if (this.page == 'edit' || this.page == 'history') {
                document.getElementById('showDetail').classList.remove('darkmode')
            }
            if (this.page == 'myBookBuy' && this.isShowChapter) {
                document.getElementById('tableScope').classList.remove('darkmode')
            }

        },
        darkmode() {
            if (this.page == 'edit' || this.page == 'history') {
                document.getElementById('showDetail').classList.add('darkmode')
            }
            if (this.page == 'myBookBuy' && this.isShowChapter) {
                document.getElementById('tableScope').classList.add('darkmode')
            }
        }
    },
};
</script>

<style scoped>
.scope {
    padding: 3% 3% 5% 3%;
}

.pageTopic {
    font-size: 20px;
    font-weight: bold;
}

.showDetail {
    background-color: #ffffff;
}

.shelf {
    border: 1rem solid #A67864;
    border-radius: 10px;
    background: #FBF2EE;
}

.shelfDivider {
    position: absolute;
    width: 100%;
    top: 48%;
    left: 0px;
    height: 7px;
    background: #A67864;
    box-shadow: inset -3px -2px 4px rgba(0, 0, 0, 0.25);
}

.menuListBox {
    font-size: 14px;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.menuListBox:hover {
    color: #ffffff;
    cursor: pointer;
    background-color: #EE806B;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.menuListBoxActive:hover {
    color: #EE806B;
    background-color: #ffffff;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.menuListBoxActive {
    color: #EE806B;
    font-weight: bold;
    background-color: #ffffff;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
    border-color: none;
    outline: none;
}

/* @media screen and (max-width: 768px) {
    
} */
.chaptertable {
    padding: inherit;
}

.tableScope {
    background-color: #ffffff;
    padding: 4% 6%;
}

.headTable {
    font-weight: bold;
    font-size: 16px;
    border-bottom: 0.5px solid rgba(143, 143, 143, 0.541);
}

.chapter {
    transform: translateZ(0px);
    transition-duration: .5s;
}

.chapter:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: rgba(238, 129, 107, 0.212);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.062);
    border-radius: 6px;
    transform: scale(1.01);
}

@media screen and (max-width: 500px) {
    .pageTopic {
        font-size: 14px;
    }

    .menuListBox {
        font-size: 14px;
        border-radius: 4px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .showDetail {
        height: auto;
    }

    .tableScope {
        padding: 4% 1%;
        font-size: 12px;
    }
}

@media screen and (min-width: 1441px) {
    .scope {
        padding: 3% 20% 5% 20%;
    }
}

.darkmode {
    background-color: #35363A;
}
</style>
